body {
    font-size: 20px;
    font-family: Athelas, serif;
}

.blurred{
    color: transparent;
    text-shadow: 0 0 20px black;
    user-select: none;
}

#invitation .warning {
    display: none;
}

#invitation.blurred .warning{
    display: block;
    color: black;
    text-shadow: none;
    user-select: auto;
    font-size: 2rem;
}

#invitation.blurred .img-info {
    filter: blur(20px);
    -webkit-filter: blur(20px);
}

.container {
    max-width: 700px;
    margin: 0 auto;
    padding: 0 12px;
}

.hidden {
    opacity: 0;
    transition: opacity 2s;
}

.img-responsive {
    width: 100%;
    height: auto;
    max-width: 100%;
    display: block;
}

.headerWrapper {
    height: 500px;
}

@media (min-width: 768px) {
    .headerWrapper {
        height: 800px;
    }
}

.gas .react-parallax-bgimage {
    max-width: 100%;
    object-fit: contain;
    object-position: 0 0;
}

.date-wrapper {
    font-size: 1.75rem;
    letter-spacing: 2px;
    margin-top: 1rem;
    margin-bottom: 3rem;
}

.date-wrapper strong {
    font-size: 3.5rem;
    margin-top: -32px;
    color: #91966e;
}

.date-wrapper time {
    display: flex;
    font-family: Athelas, serif;
    justify-content: center;
}

.date-wrapper time > * {
    display: inline-block;
    vertical-align: middle;
    margin-left: 7px;
    margin-right: 7px;
}

.place-wrapper {
    margin-bottom: 1rem;
    font-size: 1.5rem;
    font-family: Athelas, serif;
}

.bold {
    font-weight: bold;
}

.subtitle {
    font-family: "Great Vibes", sans-serif;
    font-size: 4rem;
    color: #91966e;
    margin-bottom: 0.75rem
}

.subtitle img {
    max-width: 220px;
    margin: 0 auto;
}

.row {
    max-width: min(82%, 550px);
    margin: 0 auto 4rem;
    text-align: center;
}

.time, .address, .price {
    margin-bottom: 1rem;
}

.time {
    font-family: Athelas, serif;
    text-transform: uppercase;
    font-size: 1.5rem;
}

.map {
    border: 1px solid lightgray;
    margin-bottom: 3rem;
}

.address {
    font-family: Athelas, serif;
}

.price{
    font-family: Athelas, serif;
    font-size: 1.2rem;
    margin-top: 2rem;
    margin-bottom: 3rem;
}

.hr {
    max-width: 100px;
    margin: 0 auto;
    padding-bottom: 3rem;
    filter: grayscale(100%);
}

.headerImage {
    position: relative;
    margin-top: 3rem;
}

.headerWords {
    position: absolute;
}

.down-arrow {
    position: fixed;
    top: calc(100vh - 50px);
    right: 7%;
    width: 0;
    height: 16px;
    border: 1px solid;
    animation: jumpInfinite 1.5s infinite;
}

.down-arrow--left {
    left: 7%;
}

.down-arrow:after {
    content: " ";
    position: absolute;
    top: 5px;
    left: -5px;
    width: 10px;
    height: 10px;
    border-bottom: 2px solid;
    border-right: 2px solid;
    transform: rotateZ(45deg);
}

@keyframes jumpInfinite {
    0% {
        margin-top: 0;
    }
    50% {
        margin-top: 20px;
    }
    100% {
        margin-top: 0;
    }
}

.number {
    font-family: Roboto, "Helvetica Neue", Helvetica, Arial, serif;
}

.text-wrapper {
    text-align: center;
    max-width: 90%;
    margin: 0 auto 3rem;
}

.checklist{
    margin-bottom: 0.5rem;
}

.guest-greeting {
    font-size: 1rem;
}

.guest-greeting span {
    display: block;
    font-size: 2rem;
    font-family: "Great Vibes", sans-serif;
}

.font-small{
    margin-top: -4px;
    font-size: 0.9rem;
}